.container {
    width: 100%;
    background-color: #F7D6CF;
    position: relative;
}

.user, .celebration, .shield {
    position: absolute;
    width: clamp(1rem, 5vw, 1.5rem);
    height: clamp(1.2rem, 5vw, 1.8rem);
}

.user {
    top: 1rem;
    right: 1.2rem;
    cursor: pointer;
}

.shield {
    top: 1rem;
    left: 1.2rem;
}

.celebration {
    bottom: 0.8rem;
    right: 0.3rem;
    width: clamp(1.5rem, 10vw, 3.3rem);
    height: clamp(1.5rem, 10vw, 3.3rem);
}

.container p {
    margin: 0;
}

.welcome-svg {
    position: absolute;
    left: 1rem;
    bottom: 2rem;
    width: 27%;
    height: 60%;
}

.welcome-svg img {
    display: block;
    margin: 0 auto;
    height: 100%;
}

.welcome {
    position: absolute;
    right: 1rem;
    bottom: 3rem;
    height: 50%;
    width: 60%;
}

.welcome > p {
    text-align: center;
    vertical-align: middle;
    margin-bottom: 1rem;
    /* font-size: clamp(10px, 4vw, 40px); */
}

.current-week {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    /* font-size: clamp(10px, 2vw, 30px); */
}

.welcome > p:first-child,
.current-week > p:first-child
{
    /* font-size: clamp(14px, 4vw, 35px); */
    /* margin-top: 2.5rem; */
    font-weight: bold;
}


/* @media (orientation: landscape) and (min-height: 35rem) {
    .welcome > p {
        
        font-size: clamp(10px, 4vw, 25px);
    }
    
    .current-week {
        
        font-size: clamp(10px, 2vw, 20px);
    }

    .welcome > p:first-child,
.current-week > p:first-child
{
    font-size: clamp(14px, 4vw, 25px);
    margin-top: 2.5rem;
    font-weight: bold;
}

} */