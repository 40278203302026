.container {
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.5);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.container > P {
    /* margin: 0.5rem; */
    /* font-size: clamp(10px, 2vw, 20px); */
}

.container > img {
    width: 70%;
    aspect-ratio: 1 / 1;
}

