.container {
  height: 30%;
  width: 100%;
  padding: 0 1rem;
}

.list {
  height: calc(100% - 4.5rem);
  gap: 0.625rem;
}

.level {
  min-width: 5rem;
  max-width: 5rem;
  height: 70%;
  cursor: pointer;
}

@media (min-width: 50rem) {
  .level {
    min-width: 7rem;
    max-width: 7rem;
    height: 85%;
  }
}