.container {
  height: 26%;
  width: 100%;
  padding: 0 1rem;
}

.title {
  line-height: 2rem;
  font-weight: bold;
  font-size: clamp(12px, 4vw, 20px);
}

.list {
  height: calc(100% - 2rem);
  gap: 0.625rem;
}

.progress {
    min-width: 6rem;
    max-width: 6rem;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.progress p {
  font-size: clamp(0.625rem, 2vw, 0.875);
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.progress div {
  width: 80%;
  /* width: 70%;
  aspect-ratio: 1 / 1;
  margin: 0.2rem; */
}
