.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    gap: 5%;
    padding: 1rem 1rem;
    
}

.header {
    margin-bottom: 1rem;
}
.header > img {
    width: min(100%, 15rem);
    margin: 0 auto;
}

.chart {
    width: 90%;
    height: 80%;
    margin: 0 auto;
}

.chart-container {
    width: 100%;
    flex-grow: 1;
    background-color: #F0F9F7;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.selectable-header {
    align-self: flex-start;
    width: 80%;
    margin: 0;
}

.nav {
    margin-inline: auto;
    width: min(100%, 20rem);
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav svg {
    width: 80%;
}

.selectable-header-ul {
    width: 80%;
    height: 2rem;
    flex-shrink: 0;
    font-size: 0.875rem;
}

.modal-message {
    flex-grow: 1;
    padding: 0.5rem;
}

.modal-button {
    border-radius: 4px;
    background-color: #28a78c;
    width: 6rem;
    height: 2rem;
    color: white;
    margin: 0 auto;
}

@media (min-width: 65em) and (min-height: 50em) {
    .header > img {
        width: 20rem;
    }
    .container {
        height: 100%;
        gap: 3%;
    }
}

@media (orientation: landscape) and (min-width: 50em) and (min-height: 37.5em) {
    .container {
        height: 100%;
        gap: 3%;
    }
}