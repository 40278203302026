.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container > img {
  width: min(10rem, 20%);
}

.login {
  position: relative;
  font-size: clamp(0.6rem, 10vw, 1.5rem);
  font-weight: bold;
}

.container > p {
    text-align: center;
}
.container label {
  position: relative;
}

.container input {
  width: 100%;
  padding: 10px 50px;
  border-radius: 1.5rem;
  border: none;
  box-shadow: 0 3px 6px #28a78c6e;
}

.container input:focus-visible {
  outline-width: 0;
}

.code::before {
    content: "";
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: var(--password-svg) center / contain no-repeat;
  }

  .email::before {
    content: "";
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: var(--email-svg) center / contain no-repeat;
  }

.form {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  position: relative;
}
.form + p {
  width: 20rem;
  text-align: justify;
  font-size: .875rem;
}
.container > button {
  border: 0;
  outline: 0;
  cursor: pointer;
}

.submit {
  background-color: #28a78c;
  color: white;
  border-radius: 2rem;
  width: 8rem;
  height: 3rem;
}
.submit:hover {
  color: #28a78c;
  background-color: #cdede6;
}

.submit:disabled {
  color: #646464;
  background-color: #afafaf;
}

.resend {
  text-align: center;
  font-size: clamp(0.6rem, 5vw, 1rem);

}

.resend > p {
    display: inline-block;
}

.resend > p:first-child {
    opacity: 0.5;
}

.resend > p:last-child {
  font-weight: bold;
  font-size: clamp(0.6rem, 5vw, 1.2rem);
  cursor: pointer;
  margin-left: 0.5rem;
}
