.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
}

.header {
    padding: 1rem;
}

.header > img {
    width: min(100%, 20rem);
    margin-inline: auto;
    margin-block: auto;
}

.selectable-header-ul {
    width: min(50%, 20rem);
    margin: 0;
    height: 2rem;
    flex-shrink: 0;
}



