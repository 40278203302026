.container {
  position: relative;
  font-size: clamp(0.6rem, 4vw, 1.2rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.container > img {
  height: 100%;
  border-radius: 0.25rem;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.title {
  padding-top: 0.25rem;
  display: inline-block;
  width: 90%;
}



.sub-title {
  font-size: clamp(0.6rem, 3vw, 1rem);
  opacity: 0.5;
}

.line {
  width: 100%;
  height: 0.1rem;
  background-color: #28a78c;
}


.play {
    position: absolute;
    top: 0.5rem;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.duration {
    position: absolute;
    top: 2.2rem;
    right: 0;

}

.play svg {
  width: 1rem;
}