.container {
  height: 50%;
  align-content: space-evenly;
  
}
.category {
  width: 20rem;
  width: 40%;
  text-align: center;
  font-size: 0.875rem;
  margin: 0.5rem;
  
}

.category  div {
  height: 100%;
  width: fit-content;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.5);
}



.category  img {
  height: 4.5rem;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}


