.container {
    position: fixed;
    width: 100%;
    height: 6rem;
    background-color: #f0fffcea;
    /* background-color: #c3fff3; */
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    bottom: 0;
    z-index: 2;
}

.container svg {
    cursor: pointer;
}

.canecel-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    transform: translate(0, -50%);
}





.radio {
    display: flex;
    justify-content: center;
    align-items: center;
}



.pdf {
    /* position: fixed;
    left: 6rem;
    top: 2rem; */
}

