.container {
    height: 100%;
    background-color: ghostwhite;
}

.title {
    text-align: center;
    line-height: 5rem;
    flex-shrink: 0;
    color: black;
    font-weight: bold;
}

.apps {
    list-style: none;
    width: 100%;
    overflow-y: auto;
    padding: 1rem;
    gap: 1rem;
}

.app {
    min-height: 5rem;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 4px solid rgb(239, 239, 239);
    background-color: rgb(239, 239, 239);
    border-radius: 8px;
    padding: 0 1rem;
}

.app  p {
    cursor: pointer;
}

.app > div  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.app > div:first-child {
    width: 70%;
    gap: 1rem;
}
.app img {
    width: 2rem;
}

.report {
    margin-left: 1rem;
    cursor: pointer;
}

.modal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    gap: 5%;
    font-family: Roboto;
    height: 40vh;
    width: 50%;
    border-radius: 8px;
}

.container textarea {
    border-radius: 8px;
}
.modal-message {
    flex-grow: 1;
    padding: 0.5rem;
}

.modal-button {
    border-radius: 4px;
    background-color: #28a78c;
    width: 6rem;
    height: 2rem;
    color: white;
    margin: 0 auto;
}

@media (max-width: 25rem) {
    .apps {
        font-size: clamp(0.6rem, 2vw, 0.875rem);
    }

    .report {
        margin-left: 0.5rem;
    }

    .app {
        min-height: 4rem;
    }
}