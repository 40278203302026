.container {
    height: 100%;
    background-color: ghostwhite;
}

.title {
    text-align: center;
    line-height: 5rem;
    flex-shrink: 0;
    color: black;
    font-weight: bold;
}

.categories {
    list-style: none;
    width: 100%;
    overflow-y: auto;
    padding: 1rem;
    gap: 2rem;
}

.category {
    min-height: 4rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 4px solid rgb(239, 239, 239);
    background-color: rgb(239, 239, 239);
    border-radius: 8px;
    padding: 0 1rem;
    cursor: pointer;
}

@media (max-width: 25rem) {
    .categories {
        font-size: clamp(0.6rem, 2vw, 0.875rem);
    }
}