.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container > img {
  width: min(10rem, 20%);
}

.login-header {
  position: relative;
  font-size: clamp(0.6rem, 10vw, 1.5rem);
  font-weight: bold;
}


.container label {
  position: relative;
}

.container input {
  width: 100%;
  padding: 10px 50px;
  border-radius: 1.5rem;
  border: none;
  box-shadow: 0 3px 6px #28a78c6e;
}

.container input:focus-visible {
  outline-width: 0
}


.email::before {
  content: "";
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: var(--email-svg) center / contain no-repeat;
}
.user::before {
  content: "";
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: var(--user-svg) center / contain no-repeat;
}
.password::before {
  content: "";
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: var(--password-svg) center / contain no-repeat;
}



.form {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  position: relative;
}

.container > button {
    border: 0;
    outline: 0;
    cursor: pointer;
}

.submit {
    background-color: #28A78C;
    color: white;
    border-radius: 2rem;
    width: 8rem;
    height: 3rem;
}
.submit:hover {
    color: #28A78C;
    background-color: #cdede6;
}

.submit:disabled {
  color: #646464;
  background-color: #afafaf;
}

.login {
  text-align: center;
  font-size: clamp(0.6rem, 5vw, 1rem);
}
.login > p {
  cursor: pointer;
}
.login > p:first-child {
  font-weight: bold;
  font-size: clamp(0.6rem, 5vw, 1.2rem);
}