.title {
  /* font-weight: bold;
    font-size: clamp(12px, 4vw, 20px); */
  line-height: 2rem;
}

.level-titles {
  width: 95%;
  margin: 0 auto;
  height: 3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.level-title {
  flex-basis: 5rem;
  min-width: 5rem;
  text-align: center;
  cursor: pointer;
  /* font-size: clamp(10px, 3vw, 18px); */
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5rem;
  height: 0.2rem;
  border-radius: 2rem;
  transition: all 0.2s;
  background-color: #28a78c;
}

.selected {
  opacity: 1;
}

.unselected {
  opacity: 0.7;
}
