.container {
    height: 100%;
    color: #28A78C;
    font-family: "Roboto";
    gap: 1rem;
    position: relative;
}

.header__welcome {
    height: 30%;
}

.header__normal {
    height: 15%;
}

.card {
    margin: 0;
    display: inline-block;
    width: calc(50% - 3rem);
    height: 12vh;
    position: relative;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.card p {
    margin: 0.5rem 0 0 0.5rem;
    font-size: clamp(10px, 4vw, 20px);
}

.card img {
    height: clamp(3rem, 80%, 10rem);
    position: absolute;
    bottom: 0.0875rem;
    right: 0.0875rem;
}

.media {
    align-self: flex-start;
}
.tutorial {
    align-self: flex-end;
}

.cards {
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
}
@media (min-width: 50rem) {
    .card {
        max-width: 20rem;
    }
}