.categories-parent {}
.categories {
    width: 100%;
    flex-basis: 20vh;
    gap: 1rem;
    flex-shrink: 0;
    margin: 0 auto;
    padding: 0 0.2rem;
}
.category {
    height: 80%;
    aspect-ratio: 1 / 1;
    position: relative;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.category svg {
    width: 80%;
    align-self: flex-end;
    margin-bottom: 0.2rem;
}

.category p {
    text-align: left;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    font-size: 0.875rem;
}

.episodes {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    list-style: none;
    gap: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}


.episodes-title {
    align-self: flex-start;
    padding-bottom: 0;
    font-size: clamp(0.6rem, 4vw, 1.5rem);
    color: black;
}
.episode {
    width: 100%;
    flex-basis: 8vh;
    min-height: 8vh;
}