.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  cursor: pointer;

  color: inherit;
  text-decoration: none; /* no underline */
}
